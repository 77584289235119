<template>
  <div class="select-menu">
    <p class="title" @click="expandMenu">
      {{ title }}
      <i class="title-arrow" :class="{ 'title-arrow-expand': isExpand }" />
    </p>
    <div v-if="isExpand" class="menu-item">
      <div v-for="(item, i) in options" :key="i">
        <a :href="item.url" :target="item.target">{{ item.label }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isExpand: false,
    };
  },
  methods: {
    expandMenu() {
      this.isExpand = !this.isExpand;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-menu {
  @include min768() {
    position: relative;
  }
  .title {
    color: #210f60;
    display: flex;
    align-items: center;

    .title-arrow {
      width: 15px;
      height: 15px;
      background: url("~@/assets/icons/arrow_icon.png") no-repeat center / 100%
        auto;
      margin-left: 5px;
      transition: all 0.3s;
      &.title-arrow-expand {
        transform: rotate(180deg);
      }
    }
  }
  .menu-item {
    @include min768() {
      position: absolute;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px;
      border-radius: 6px;
      overflow: hidden;
      top: 34px;
      min-width: 150px;
    }
    > div {
      margin-top: 20px;
      @include min768() {
        margin: 0;
        padding: 20px 30px;
        &:hover {
          background: #e4f8f6;
        }
      }
    }
    a {
      color: #000;
      font-size: 16.67px;
      line-height: 30px;
      &:hover {
        color: $primary-color !important;
      }
    }
  }
}
</style>

<template>
  <div class="common-footer">
    <div class="top-info">
      <div class="footer-item">
        <p class="title">Company</p>
        <div class="menu-item">
          <a v-for="(item, i) in navConfig.company" :key="i" :href="item.url">
            {{ item.name }}
          </a>
        </div>
      </div>
      <div class="footer-item">
        <p class="title">Resources</p>
        <div class="menu-item">
          <a v-for="(item, i) in navConfig.resources" :key="i" :href="item.url">
            {{ item.name }}
          </a>
        </div>
      </div>
      <div class="footer-item">
        <p class="title">Discover</p>
        <div class="menu-item">
          <a v-for="(item, i) in navConfig.discover" :key="i" :href="item.url">
            {{ item.name }}
          </a>
        </div>
      </div>
      <div class="footer-item">
        <p class="title">Opay Office</p>
        <p class="office-content">
          <span>HQ:</span>
          Alexander House, Otunba Jobi Fele Way, Ikeja, Lagos
        </p>
      </div>
      <div class="footer-item">
        <p class="title">Customer Service Centers</p>
        <p v-for="(item, i) in customerDesc" :key="i" class="office-content">
          <span>{{ item.prefixTxt }}</span>
          {{ item.contentTxt }}
        </p>
      </div>
      <div class="download-item">
        <p class="title">download the OPay app</p>
        <a href="https://bit.ly/OPayonGooglePlay">
          <img src="@/assets/android.png" alt="" />
        </a>
        <a href="https://bit.ly/OPayoniOS">
          <img src="@/assets/apple.png" alt="" />
        </a>
      </div>
    </div>
    <div class="bottom-info">
      <img class="logo" src="@/assets/opay_logo_white.png" alt="" />
      <p class="tip">© 2112023 OPay Digital Services Limited.</p>
      <div class="share-icons">
        <a v-for="(item, i) in shareMenu" :href="item.href" :key="i">
          <img :src="item.assets" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { navConfig, customerDesc } from "./constant";
export default {
  data() {
    return {
      navConfig,
      customerDesc,
      shareMenu: [
        {
          assets: require("@/assets/icons/icon_fb_mob.png"),
          href: "https://www.facebook.com/opay.ng/",
        },
        {
          assets: require("@/assets/icons/icon_twitter_mob.png"),
          href: "https://twitter.com/OPay_NG",
        },
        {
          assets: require("@/assets/icons/icon_ins_mob.png"),
          href: "https://www.instagram.com/opay.ng/",
        },
        {
          assets: require("@/assets/icons/icon_linkedin_mob.png"),
          href: "https://www.linkedin.com/company/opay/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.common-footer {
  .top-info {
    padding: 33.3px 25px;
    background: $primary-color;
    text-align: left;
    color: #fff;
  }
  .footer-item {
    &:nth-child(n + 2) {
      margin-top: 33.3px;
    }
  }
  .title {
    font-size: 18.75px;
    font-weight: bold;
  }
  .menu-item {
    display: flex;
    flex-direction: column;

    > a {
      font-size: 16.67px;
      line-height: 31.25px;
      margin-top: 8.33px;
      color: #fff;
    }
  }
  .office-content {
    color: #fff;
    font-size: 12.5px;
    line-height: 25px;
    margin-top: 8.33px;
    > span {
      font-weight: bold;
    }
  }
  .download-item {
    margin-top: 40px;

    a {
      &:nth-child(n + 3) {
        margin-left: 34.38px;
      }
    }
    img {
      width: 91.67px;
      vertical-align: top;
      margin-top: 16.67px;
    }
  }
  .bottom-info {
    padding: 25px;
    background: #210f60;
    text-align: left;
    .logo {
      width: 65.63px;
      display: block;
    }
    .tip {
      font-size: 12.5px;
      color: $primary-color;
      line-height: 25px;
      margin-top: 16.67px;
    }
    .share-icons {
      display: flex;
      justify-content: space-between;
      margin-top: 16.67px;
      img {
        width: 41.67px;
      }
    }
  }
}
</style>

<template>
  <div class="common-footer">
    <div class="part-top">
      <!-- Company -->
      <div class="part-top-info w95">
        <div class="part-top-info-title">Company</div>
        <a v-for="(item, i) in navConfig.company" :key="i" :href="item.url">{{
          item.name
        }}</a>
      </div>
      <!--  -->
      <div class="part-top-info w120">
        <div class="part-top-info-title">Resources</div>
        <a v-for="(item, i) in navConfig.resources" :key="i" :href="item.url">{{
          item.name
        }}</a>
      </div>
      <div class="part-top-info w65">
        <div class="part-top-info-title">Discover</div>
        <a v-for="(item, i) in navConfig.discover" :key="i" :href="item.url">{{
          item.name
        }}</a>
      </div>
      <div class="part-top-info w160">
        <div class="part-top-info-title">Opay Office</div>
        <p>
          <span>HQ:</span>Alexander House, Otunba Jobi Fele Way, Ikeja, Lagos
        </p>
      </div>
      <div class="part-top-info">
        <div class="part-top-info-title">Customer Service Centers</div>
        <p v-for="(item, i) in customerDesc" :key="i">
          <span>{{ item.prefixTxt }}</span>
          {{ item.contentTxt }}
        </p>
      </div>
    </div>
    <div class="part-bt">
      <img src="@/assets/opay_logo.png" alt="" />
      <span class="limit-desc">© 2112023 OPay Digital Services Limited.</span>
      <div class="share-icon">
        <a v-for="(item, i) in shareMenu" :key="i" :href="item.href">
          <img :src="item.assets" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { navConfig, customerDesc } from "./constant";

export default {
  data() {
    return {
      navConfig,
      customerDesc,
      shareMenu: [
        {
          assets: require("@/assets/icons/facebook.png"),
          href: "https://www.facebook.com/opay.ng/",
        },
        {
          assets: require("@/assets/icons/twitter.png"),
          href: "https://twitter.com/OPay_NG",
        },
        {
          assets: require("@/assets/icons/instagram.png"),
          href: "https://www.instagram.com/opay.ng/",
        },
        {
          assets: require("@/assets/icons/linkedin.png"),
          href: "https://www.linkedin.com/company/opay/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.common-footer {
  .part-top {
    width: 100%;
    background: url("~@/assets/footer_bg_pc.png") no-repeat top center / 100%
      auto;
    background-color: #1dcf9f;
    padding: 51.67px 297.92px 38.33px 259.58px;
    display: flex;
    box-sizing: border-box;
    &-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      &-title {
        font-weight: 700;
        color: #fff;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 13.3px;
      }
      > a {
        font-size: 13.33px;
        line-height: 25px;
        color: #fff;
        &:nth-child(n + 3) {
          margin-top: 13.3px;
        }
      }
      > p {
        color: #fff;
        font-size: 10px;
        line-height: 20px;
        > span {
          font-weight: bold;
        }
      }

      &:nth-child(n + 2) {
        margin-left: 60px;
      }
    }
    .w95 {
      width: 95px;
    }
    .w120 {
      width: 120px;
    }
    .w65 {
      width: 65px;
    }
    .w160 {
      width: 160px;
    }
  }
  .part-bt {
    width: 100%;
    height: 101.67px;
    background: #d2f5ec;
    display: flex;
    align-items: center;
    padding: 0 100.8px 0 110px;
    justify-content: space-between;
    box-sizing: border-box;
    > img {
      width: auto;
      height: 40px;
    }
    .limit-desc {
      font-family: "Roboto";
      font-size: 11.67px;
      color: #210f60;
    }
    .share-icon {
      display: flex;
      a {
        display: inline-block;
        font-size: 0;
        outline: none;
        text-decoration: none;
      }
      img {
        width: 33.4px;
        height: auto;
        margin-right: 8px;
      }
    }
  }
}
</style>

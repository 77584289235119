import { Loading, Button, Input, Message, MessageBox } from "element-ui";

import "@/styles/element-variables.scss";

const { alert, confirm, prompt } = MessageBox;

export default (Vue) => {
  Vue.use(Input);
  Vue.use(Loading);
  Vue.use(Loading.directive);
  Vue.use(Button);

  Vue.prototype.$confirm = confirm;
  Vue.prototype.$alert = alert;
  Vue.prototype.$prompt = prompt;
  Vue.prototype.$message = Message;
};

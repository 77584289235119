<template>
  <div class="header">
    <img class="header-logo" src="@/assets/opay_logo.png" alt="" />
    <div class="menu-items">
      <div v-for="(item, i) in menus" :key="i">
        <a
          v-if="item.url"
          class="menu-item"
          :href="item.url"
          :target="item.target"
        >
          {{ item.label }}
        </a>
        <selectMenu
          v-if="item.options"
          :title="item.label"
          :options="item.options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { headerMenus } from "../constant";
import selectMenu from "../select-menu.vue";
export default {
  components: {
    selectMenu,
  },
  data() {
    return {
      menus: headerMenus,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 96.67px;
  line-height: 96.67px;
  padding-left: 95px;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 999;
  &-logo {
    width: 168px;
    height: auto;
  }
  .menu-items {
    display: flex;
    margin-left: 28px;
    color: #210f60;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
    a {
      color: #210f60;
      &:hover {
        color: $primary-color !important;
      }
    }
    > div {
      padding: 0 13px;
      &:nth-child(n + 2) {
        margin-left: 33px;
      }
    }
  }
}
</style>

export const navConfig = {
  company: [
    { name: "About us", url: "https://opayweb.com/about-us" },
    { name: "Press & Media", url: "https://opayweb.com/" },
    { name: "Contact us", url: "https://opayweb.com/contact-us" },
    { name: "Report an Issue", url: "/report" },
  ],
  resources: [
    {
      name: "Privacy Policy",
      url: "https://opayweb.com/privacy-policy",
    },
    {
      name: "Terms & Conditions",
      url: "https://opayweb.com/terms-and-conditions",
    },
    {
      name: "Documentation",
      url: "https://documentation.opaycheckout.com/",
    },
    {
      name: "Security",
      url: "https://opayweb.com/security",
    },
  ],
  discover: [
    {
      name: "Personal",
      url: "https://opayweb.com",
    },
    {
      name: "Business",
      url: "https://opaybusiness.opayweb.com/home",
    },
  ],
};

export const customerDesc = [
  {
    prefixTxt: "ABUJA:",
    contentTxt:
      "Uyk Hexahub Plaza, Balanga Cresent off Uke Street by Bolton White Hotel, opposite Hawthrone Suites, Garki 11, Abuja",
  },
  {
    prefixTxt: "LAGOS, ALLEN IKEJA:",
    contentTxt: "No 103 Mosesola House, Allen Road, Ikeja Lagos",
  },
  {
    prefixTxt: "LAGOS, FESTAC:",
    contentTxt:
      "4th Avenue A Close, Festac Town Beside Quincess School, Festac, Lagos",
  },
  {
    prefixTxt: "LAGOS,IKORODU:",
    contentTxt: "78B Lagos Road Ikorodu Beside Micro Station Slot",
  },
  {
    prefixTxt: "ABEOKUTA:",
    contentTxt: "Dolly House Opposite Laroy Hotel, Abiola Way",
  },
  {
    prefixTxt: "IBADAN:",
    contentTxt:
      "Tricennial House, 189B, Fajuyi Road, Beside Ideal Consult, Opposite Thermocool Showroom, Along Adamasingba road, Eleganza, Ibadan",
  },
  {
    prefixTxt: "GOOD:",
    contentTxt:
      "Thilda'S Center, Shop 12 Opposit Gig Logistics Dadin Kowa 3Rd Gate Jos",
  },
  {
    prefixTxt: "KADUNA:",
    contentTxt: "Asd City Mall Katsina Road By Independent Way,Kaduna State",
  },
  {
    prefixTxt: "KANO:",
    contentTxt: "Amana Plaza Second Floor Beside Shoprite Zoo Road,Kano State",
  },
  {
    prefixTxt: "OSOGBO:",
    contentTxt:
      "Opposite Technical Besides Arowolo Filling Station Osogbo, Osun State",
  },
  {
    prefixTxt: "BENUE:",
    contentTxt: "Chiventis Plaza, Atom Kpera OAD, Modern Market",
  },
  {
    prefixTxt: "AKWA IBOM:",
    contentTxt:
      "Plot 104 Nsikak Eduok Ave, Homelight Properties, After Full Life Christian Centre, Two Lane Uyo",
  },
  { prefixTxt: "IMO:", contentTxt: "No.138 Tetlow Road, off Lagos Street" },
  { prefixTxt: "ABIA:", contentTxt: "135, Azikiwe by Yoke, Southabia State" },
  {
    prefixTxt: "KOGI:",
    contentTxt: "No 100, 1BB Waymoremi House, Lokoja, Kog State",
  },
  {
    prefixTxt: "PORTHARCOURT:",
    contentTxt:
      "Plot 79 Evo Road, opposite Medicine Mart Phamacy GRA Porthacout Rivers State",
  },
  {
    prefixTxt: "Edo:",
    contentTxt:
      "Elora Royal Event Centre N0.162 Benin Sapele Road, after Magistrate Court, before Eriaria junction, Benin City, Edo State",
  },
];

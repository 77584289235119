/**
 * label: 描述
 * url: 跳转地址，站外为完成地址，站内为route name
 * options: 下拉选项
 */
export const headerMenus = [
  {
    label: "Personal",
    url: "https://opayweb.com/",
    target: "",
  },
  {
    label: "Business",
    url: "https://opaybusiness.opayweb.com/home",
    target: "_blank",
  },
  {
    label: "Company",
    options: [
      { label: "About Us", url: "https://opayweb.com/about-us", target: "" },
      {
        label: "Contact Us",
        url: "https://opayweb.com/contact-us",
        target: "",
      },
    ],
  },
  {
    label: "CSR",
    url: "https://opayweb.com/csr",
    target: "",
  },
];

<template>
  <div id="app" :class="{ 'has-header-page': !noCommonHeader }">
    <commonHeader v-if="!noCommonHeader" />
    <div :class="{ 'has-header-content': !noCommonHeader }">
      <router-view />
    </div>
    <commonFooter v-if="!noCommonFooter" />
  </div>
</template>
<script>
import commonHeader from "@/components/common-header/index.vue";
import commonFooter from "@/components/common-footer/index.vue";

export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data() {
    return {};
  },
  computed: {
    // 不需要公共header
    noCommonHeader() {
      return this.$route.meta.noNeedHeader;
    },
    // 不需要公共footer
    noCommonFooter() {
      return this.$route.meta.noNeedFooter;
    },
  },
  mounted() {
    this.resizeFn();
    window.addEventListener("resize", this.resizeFn);
  },
  methods: {
    resizeFn() {
      const userAgent = window.navigator.userAgent;
      const isMobileUa =
        /Mobi|Android|webOS|iPhone|iPod|BlackBerry|iPad|Mobile|iemobile|opera mini|MiuiBrowser/i.test(
          userAgent
        );
      const clientWidth =
        window.document.documentElement.clientWidth ||
        window.document.body.clientWidth;
      const isMobileDevice = clientWidth < 768 || isMobileUa;
      this.$store.dispatch("toggleDeviceMode", isMobileDevice);
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  &.has-header-page {
    padding-top: 96.67px;
    @include max768() {
      padding-top: 56px;
    }
  }

  .has-header-content {
    min-height: calc(100vh - 96.67px);

    @include max768() {
      min-height: calc(100vh - 56px);
    }
  }
  a {
    outline: none;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>

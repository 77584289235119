import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

/**
 * meta: {
 *  @noNeedHeader 不需要公共header
 *  @noNeedFooter 不需要公共footer
 * }
 */

const routes = [
  {
    path: "/report",
    name: "report-page",
    component: () => import("@/views/report"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div class="mobile-header">
    <img class="header-logo" src="@/assets/opay_logo.png" alt="" />
    <img
      v-show="!isExpand"
      class="header-menu"
      src="@/assets/icons/menu.png"
      alt=""
      @click="expandMenu"
    />
    <img
      v-show="isExpand"
      class="header-menu-close"
      src="@/assets/icons/close_icon_green.png"
      alt=""
      @click="expandMenu"
    />
    <div class="menu" :class="{ 'expand-menu': isExpand }">
      <div
        v-for="(item, i) in menus"
        :key="i"
        class="menu-item"
        :class="{ 'menu-item-arrow': item.options }"
      >
        <a
          v-if="item.url"
          class="menu-item"
          :href="item.url"
          :target="item.target"
        >
          {{ item.label }}
        </a>
        <selectMenu
          v-if="item.options"
          :title="item.label"
          :options="item.options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { headerMenus } from "../constant";
import selectMenu from "../select-menu.vue";
export default {
  components: {
    selectMenu,
  },
  data() {
    return {
      menus: headerMenus,
      isExpand: false,
    };
  },
  methods: {
    expandMenu() {
      this.isExpand = !this.isExpand;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 58.33px;
  background: #f4fcfa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  padding: 0 16.67px;
  box-sizing: border-box;

  .header-logo {
    width: 83.33px;
  }
  .header-menu {
    width: 33.33px;
  }
  .header-menu-close {
    width: 33.33px;
  }
  .menu {
    position: fixed;
    background: rgb(244, 252, 250);
    box-sizing: border-box;
    height: calc(100vh - 58.33px);
    top: 100vh;
    left: 0;
    right: 0;
    z-index: 999;
    transition: all 250ms linear 0s;
    padding: 28px 33.3px 0;
    text-align: left;
    &.expand-menu {
      top: 58.33px;
    }
    .menu-item {
      font-size: 25px;
      line-height: 35px;
      a {
        color: #210f60;
        &:hover {
          color: $primary-color !important;
          font-weight: bold;
        }
      }
      &:nth-child(n + 2) {
        margin-top: 44.79px;
      }
    }
  }
}
</style>

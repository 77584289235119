import { render, staticRenderFns } from "./mobile-footer.vue?vue&type=template&id=05b5ff11&scoped=true"
import script from "./mobile-footer.vue?vue&type=script&lang=js"
export * from "./mobile-footer.vue?vue&type=script&lang=js"
import style0 from "./mobile-footer.vue?vue&type=style&index=0&id=05b5ff11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b5ff11",
  null
  
)

export default component.exports